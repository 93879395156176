import React from 'react'
import { navigate } from 'gatsby'

const Slot = props => {

  function showCheckout(){
    navigate('/booking-checkout?v='+window.localStorage.getItem('appVersion'),{state:{subject:props.subject, level: props.level, faculty: props.faculty, slot: props.slot}});
  }

  return (
    <div className='row m-sm'>
      <div className='card-white border-radius class-list col-10'>
      
      <div className='text-left mx-sm' style={{float:'left'}}>
        <p className='mb-0' style={{ fontWeight: 600 }}>
          <span>{new Date(props.slot.date).toLocaleString('default', {  dateStyle: 'full' })}</span>   
          <span className="text-blue">  {props.slot.start_time.slice(0,5)} - {props.slot.end_time.slice(0,5)}</span>
        </p>
      </div>
      </div>
      <i
                className='icon icon-Right ml-auto a-self-center'
                style={{ fontSize: 40, cursor: 'pointer' }}
                onClick={()=>showCheckout()}
            />
    </div>
  )
}

export default Slot
